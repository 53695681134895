/*
* 
*/
/* Tablet */

@media (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .notification-container {
    width: 100%;
  }
  .tab-title{
    font-size: 1rem;
  }
  .cross{
    font-size: 2rem;
    position: sticky !important;
  }
  .new-tag {
    font-size: 0.55rem;
  }
  .update-parah {
    font-size: 0.9rem;
  }
  .update-date {
    font-size: 0.9rem;
  }
}
