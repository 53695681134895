.design {
  z-index: 10;
  height: 50px;
  background: rgb(252, 52, 52);
  background: linear-gradient(
    90deg,
    rgba(252, 52, 52, 1) 11%,
    rgba(251, 100, 63, 1) 36%,
    rgba(251, 108, 65, 1) 54%,
    rgba(251, 143, 75, 1) 76%,
    rgba(252, 130, 76, 1) 92%
  );
}
.la {
  margin: 0;
  padding: 0;
}
.banner {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  column-gap: 30px;
  align-items: center;
  padding-left: 31px;
}
.txt {
  color: #fcd859;
  /* text-shadow: -1px -1px 0px #786c59, 1px -1px 0px #786c59,
    -1px 1px 0px rgba#786c59, 1px 1px 0px #786c59; */
  font-size: large;
  font-weight: 600;
}
.design:hover {
  text-decoration: none;
}
.try_link {
  color: #2b234a;
  text-decoration: underline;
  font-size: small;
}
.try_link:hover {
  color: #2b234a;
}
