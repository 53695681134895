.sub-links{
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
    align-items: center;
}

.sub-links > p{
    padding: 5px;
    margin: 0;
}

.checkout-terms{
    color:#01d6a6;
    padding: 0 2px;
    text-decoration: none !important;
    cursor: pointer;
}

.checkout-terms:hover{
    color:#01d6a6;
    padding: 0 2px;
    text-decoration: none !important;
    cursor: pointer;
}

.checkout-sub-heading{
    color:#01d6a6;
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 700;
}
.checkout-sub-heading2{
    padding: 0.5rem;
    text-align: center;
    background-color: white;
    color: black;
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 700;
}

.checkout-sub-topics{
    font-size: 17px;
    margin-top: 15px;
}

.checkout-edit-icon{
    display: flex;
    color:#01d6a6;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.checkout-sub-container{
    padding: 1.5rem;
    background-color: #f6f7fa;
    color: black;
    text-align: left;
}

.checkout-sub-heading{
    padding: 0.5rem;
    text-align: center;
    background-color: white;
}

.checkout-agree{
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}

.address-form-container{
    /* padding: 1.5rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 70rem; */
    background-color: white;
}

.address-form-heading{
    font-weight: 700;
    font-size: 36px;
    padding-top: 2rem;
}

.address-form-sub-container, .checkout-sub-container{
    padding: 3.5rem 4rem;
    background-color: #f6f7fa;
    color: black;
    text-align: left;
    border-radius: 1rem;
    font-size: 13px;
    max-width: 35rem;
    text-align: left;
}

.step-desc{
   color: #c8c8c8;
}

.form-group, .form-control{
    font-size: 13px;
}

.address-form-address{
    margin-top: 1rem;
    font-size: 11px;
    color: #d9dad9;
    text-align: center;
}

.sweet-alert{
    width: 90%;
}

.checkout-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
}

.checkout-header-x{
    cursor:pointer;
    color: red;
}

.checkout-submitButton {
    font-size: 16px;
    width: 40%;
    border-radius: 1rem;
    height: 50px;
    margin: 25px 30% 0px 30%;
    background-color: #49c0a0 !important;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #49c0a0 !important;
    color: #fff !important;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
    border: none;
  }


  input[type="checkbox"] {
    color:#01d6a6;
    accent-color:#01d6a6;
  }

  .planlist-show-message{
    color:red;
    font-size: 10px;
    position: absolute;
  }

  .profile-login--title{
    text-align: center;
  }

 .step3-details{
    margin: 0;
    font-size: 11px;
 }

 @media (max-width: 600px) {
    .address-form-sub-container, .checkout-sub-container{
        padding: 2.5rem;
    }
    .sub-links{
        padding: 0;
    }
    .checkout-submitButton{
        width: 70%;
        margin: 25px 15% 0px 15%;
        height: 45px;
    }
 }
 @media (max-width: 390px) {
    .address-form-sub-container, .checkout-sub-container{
        padding: 1.5rem;
    }

 }