.divider {
  display: none;
}
.generate--bg--title {
  margin-bottom: 0;
}
.custom-style-top {
  margin-top: 0;
  padding-top: 0;
}
.height-cover-qm {
  min-height: 87vh;
}
.mobile-height-cover {
  min-height: 85vh !important;
}
.nav-items-container {
  list-style: none;
  display: flex;
  padding: 0;
  column-gap: 10px;
}
.nav-list {
  border: 1px solid var(--color-primary);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: small;
  cursor: pointer;
}
.apply-bg {
  background: var(--color-primary);
  color: #fff !important;
}
.nav-link {
  column-gap: 10px;
  /* border: 1px solid var(--color-text); */
  /* border-radius: 4px; */
  background: var(--color-white);
}

/*
* Demo Header and Title Section 
*/

.notify-tp-hots {
  position: relative !important;
  right: -0.7rem !important;
  top: 0px !important;
}

.notify-tp-special {
  position: relative !important;
  right: -0.7rem !important;
  top: 0px !important;
}

.dropdown_video {
  display: "flex";
  align-items: "center";
  padding: 10px;
  transition: "background-color 0.2s ease";
  padding-left: 170px;
}
.singleSelection {
  margin-right: 8px;
}
.notify-tool-tip {
  position: relative;
  display: inline-block;
}

.notify-tool-tip .hoverTextVisualsPop {
  visibility: hidden;
  background-color: #08164e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 99;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  top: 6px;
  width: 330px;
  left: 40px;
}

.notify-tool-tip:hover .hoverTextVisualsPop {
  visibility: visible;
}

/* Tablet */

@media (min-width: 768px) and (max-width: 1023px) {
  .generate--bg--title > h3 {
    font-size: 2.3rem;
  }
  .generate--bg--title > span {
    font-size: 1.2rem;
  }
  .notify-tp-hots {
    position: relative !important;
    right: -0.7rem !important;
    top: 0px !important;
  }
  .notify-tp-special {
    position: relative !important;
    right: -0.7rem !important;
    top: 0px !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .notify-tool-tip .hoverTextVisualsPop {
    top: 26px;
    font-size: 10px;
    left: -260px !important;
    width: 296px;
  }

  .upgrade-plan-btn,
  .upgrade-plan-btn-v2 {
    font-size: 0.8rem;
    margin: 0 !important;
  }
  .navbar-nav {
    margin-top: 10px;
  }
  .demo-login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-link {
    margin: 0;
  }
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-item {
    margin: 0 !important;
  }
  .divider {
    width: 100%;
    margin: 0.5rem 0;
    border-top: 1px solid rgb(203, 203, 203);
    display: block !important;
  }
  .generate--bg--title > h3 {
    font-size: 1.6rem;
    line-height: normal;
  }
  .generate--bg--title > span {
    font-size: 0.9rem;
  }

  .mobile-height-cover {
    min-height: 85vh !important;
  }
  .notify-tp-hots {
    position: relative !important;
    right: -0.4rem !important;
    top: 1px !important;
  }
  .notify-tp-special {
    display: none;
    position: relative !important;
    right: -0.4rem !important;
    top: 1px !important;
  }
  .toggle-btn-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dropdown_video {
    display: "flex";
    align-items: "center";
    padding: 10px;
    font-size: small;
    transition: "background-color 0.2s ease";
  }
  .singleSelection {
    margin-right: 0px;
    font-size: small;
  }
  .selected_respon {
    font-size: 12px !important;
  }
}

/*
* Demo General Section
*/

.toggle-gq-demo {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-items: center;
  margin-top: 2.5rem;
  background-color: transparent;
  z-index: 111;
  transform: translateY(0.8px);
}

.toggle-btn-main {
  padding: 1rem;
  font-weight: 600;
  overflow: visible;
  width: 270px;
  text-decoration: none !important;
  margin: 0 0.4rem;
  text-align: center;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-btn-main:disabled {
  cursor: not-allowed !important;
}

.demo-custom-margin {
  margin-top: 150px;
}

.container-custom-margin {
  margin-top: 50px;
}

.speciliast-custom-container {
  max-width: 1220px !important;
}

.video-crown {
  margin-left: 61px !important;
}
.upload-crown {
  top: -6px !important;
  margin-left: 158px !important;
  /* right: 0px !important; */
}

.qtypes-crown {
}

.qcounts-crown {
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .toggle-choose-label {
    display: none !important;
  }
  .toggle-btn-main {
    padding: 0.5rem 0rem !important;
    font-weight: 600;
    overflow: visible;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    font-size: 12px;
  }
  .demo-custom-margin {
    margin-top: 120px;
  }
  .container-custom-margin {
    margin-top: 20px;
  }
  .demo-custom-container {
    max-width: 1023px !important;
  }
  .specialist-crown {
    right: 20px !important;
  }
  .hots-crown {
    right: 20px !important;
  }
  .video-crown {
    margin-left: 61px !important;
  }
  .upload-crown {
    top: -6px !important;
    margin-left: 140px !important;
    /* right: -7px !important; */
  }

  .qtypes-crown {
    margin-left: 101px !important;
  }

  .qcounts-crown {
    margin-left: 111px !important;
  }
}
/* Mobile */
@media only screen and (max-width: 767px) {
  .demo-popup-logo {
    width: 175px !important;
    height: 154px !important;
  }
  .toggle-choose-label {
    display: none !important;
  }
  .toggle-btn-main {
    padding: 0.4rem 1.2rem !important;
    font-size: 9px;
    font-weight: 600;
    overflow: visible;
    flex-direction: row;
  }
  .demo-custom-margin {
    margin-top: 110px;
  }
  .specialist-crown {
    right: -4px !important;
    top: -6px !important;
  }
  .hots-crown {
    right: -4px !important;
  }
  .video-crown {
    margin-left: 56px !important;
  }
  .upload-crown {
    top: -9px !important;
    margin-left: 117px !important;
    /* right: 24px !important; */
  }

  .qtypes-crown {
    margin-left: 87px !important;
  }

  .qcounts-crown {
    margin-left: 94px !important;
  }
}

/*
* Question Generate Std & Hots & Speciliast
*/

.question-generate-item-text {
  color: var(--color-text);
  font-weight: 500;
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-generate-item {
  flex: 100% 100% 100% !important;
}

.apply-bg {
  background: var(--color-primary);
}

.apply-bg > a {
  color: #fff !important;
}
.specialist-btn-margin-top-custom {
  margin-top: 18px;
}

.btn-px-custom {
  padding: 0.5rem 5rem !important;
}

.video--content {
  padding: 6rem 10px;
  background: #f1f7fe;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wikiContent,
#contentForQuestions {
  margin-bottom: 1.5rem;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .specialist-btn-margin-top-custom {
    margin-top: 12px;
  }
  .question-generate-tab-label {
    font-size: 14px !important;
  }
  .question-generate-tab-input {
    font-size: 14px !important;
  }
  .question-generate-item-text {
    font-size: 14px !important;
  }
  .question-generate-submit {
    font-size: 600;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .video--content-font label {
    text-align: center;
  }
  .btn-px-custom {
    padding: 0.5rem 3rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .specialist-btn-margin-top-custom {
    margin-top: 12px;
  }
  .btn-px-custom {
    padding: 0.5rem 1rem !important;
  }
  /* .video--content-font-label label {
    text-align: center;
  } */
  .question-generate-tab-label {
    font-size: 12.3px !important;
  }
  .question-generate-tab-input {
    font-size: 13px !important;
  }
  .question-generate-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .question-generate-item-text {
    font-size: 13px !important;
  }

  .question-generate-item {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px;
  }

  .border-zero {
    border: 0 !important;
  }

  .question-generate-submit {
    font-size: 600;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .question-generate-item {
    flex: 0 0 45% !important;
    margin-bottom: 0;
  }
}

/*
* Generated Questions Std & Hots & Speciliast
*/
.generated-questions-top-section {
  flex-wrap: nowrap;
  margin-right: 1.5rem;
}

.front-action-buttons-responsive {
  width: 100% !important;
  gap: 10px !important;
  margin-right: 2rem;
}
.front-action-buttons-text-responsive {
  white-space: nowrap;
}

.conduct-test-dropdown {
  left: 222px !important;
  top: 10px !important;
  margin-top: 0 !important;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .section-generated-title-responsive {
    margin: 0 0 1rem -1rem;
    font-size: 18px !important;
  }
  .generated-questions-top-section {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 !important;
  }
  .front-action-buttons-responsive {
    /* max-width: 80% !important; */
    /* flex: 0 0 80%; */
    gap: 10px !important;
    justify-content: flex-end;
    margin-right: 3rem;
    /* padding-right: 0; */
  }
  .front-action-buttons-text-responsive {
    padding: 0.5rem 0.7rem !important;
    text-align: center;
    font-size: 14px !important;
  }
  .category-widget-responsive {
    padding: 10px 12px !important;
  }
  .categories-title {
    font-size: 16px !important;
    padding-left: 0 !important;
  }
  .category-nav-link {
    padding: 8px 10px !important;
    font-size: 13px !important;
  }
  .category-nav-link span {
    right: 8px !important;
    top: 8px !important;
  }
  .conduct-test-dropdown {
    left: 186px !important;
    top: 2px !important;
    margin-top: 0 !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .pagination-text {
    font-size: 12px;
  }
  .generated-questions-top-section {
    flex-wrap: wrap;
    margin: 0 !important;
  }
  .section-generated-title-responsive {
    margin: 0 0 1rem 0rem;
    font-size: 16px !important;
  }
  .front-action-buttons-responsive {
    flex-wrap: wrap;
    justify-content: flex-start;
    /* margin-left: 1rem; */
    padding-right: 0;
  }
  .front-action-buttons-text-responsive {
    width: 8rem;
    padding: 0.5rem 0.6rem !important;
    text-align: center;
    font-size: 12px !important;
  }
  .category-widget-responsive {
    padding: 10px 12px !important;
    margin-bottom: 2rem;
  }
  .categories-title {
    font-size: 16px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0 !important;
    /* margin-bottom:0 !important; */
  }
  .category-nav-link {
    padding: 10px 12px !important;
    font-size: 14px !important;
  }
  .add-quetsion--btn {
    font-size: 14px !important;
    margin-bottom: 2rem !important;
  }
  .qna-wrapper {
    padding: 12px 8px;
  }
  .swal-preview {
    padding: 0.2rem 0.2rem !important;
  }
  .swal-cross-btn {
    top: -5px;
    right: 2px;
  }
  .conduct-test-dropdown {
    left: 196px !important;
    top: 2px !important;
    margin-top: 0 !important;
  }
}

/*
* Custom Screen Size media queries
*/
@media (min-width: 1024px) and (max-width: 1200px) {
  .custom-width {
    /* flex: 1 0 83.333333% !important; */
  }
}

@media (max-width: 400px) {
  .toggle-btn-main {
    padding: 0rem 1.2rem !important;
    font-size: 11px;
    font-weight: 600;
    overflow: visible;
    flex-direction: column;
    height: 40px;
    margin: 0 2px;
  }
}
.nav-link {
  column-gap: 10px;
  /* border: 1px solid var(--color-text); */
  /* border-radius: 4px; */
  background: var(--color-white);
}
.question-generate-item {
  border: 1px solid var(--color-primary) !important;
  border-radius: 17px;
}
.question-generate-item-text {
  color: var(--color-primary);
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: small;
  padding: 8px 25px;
}

.border-new {
  border-style: dashed !important;
  border-color: var(--color-primary);
  border-width: 2px;
}
.border-nonactive {
  border-style: dashed !important;
  border-color: #c6c6c6 !important;
  cursor: not-allowed;
  border-width: 2px;
}

.extract-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.extract-text {
  color: #c6c6c6;
}
.extract-word {
  color: black;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  align-content: center;
}
.extract-word-warning {
  color: red;
  background-color: #c6c6c6;
  font-weight: 600;
  font-size: 8px;
  border-radius: 3px;
  padding: 0px 15px;
  text-align: center;
  align-content: center;
}
.green-label {
  color: #44bfa2;
  font-weight: 600;
}
.border-active {
  border-style: dashed !important;
  border-color: #c6c6c6;
  border-width: 2px;
}
.border-active:focus {
  border-color: #c6c6c6 !important;
}
.webContant-flex {
  display: flex;
  column-gap: 15px;
}
.extracted-btn {
  border-radius: 5px;
  color: var(--color-primary);
  border: 1px solid #c6c6c6;
  background-color: #f1f7fe;
}
.extracted-btn:hover {
  background-color: #44bfa2;
  color: white;
}
.extracted-btn:focus {
  outline: 2px solid #44bfa2;
  outline-offset: 2px;
  background-color: #44bfa2;
  color: white;
}
.ext-word {
  display: flex;
  flex-direction: row-reverse;
}
.p-input {
  padding: 8.2px 5px !important;
}
.error-border {
  border-color: red !important;
}
.word-exceed {
  color: red;
}
.hidden-textarea {
  display: none;
}

.text-red {
  color: red;
}

.editable-text {
  white-space: pre-wrap; /* Ensures the text wraps correctly */
  word-wrap: break-word; /* Ensures long words break and wrap to the next line */
  overflow-wrap: break-word; /* Helps in breaking long words at the container boundary */
  width: 100%; /* Ensures the div takes up the full width of its container */
  box-sizing: border-box; /* Prevents the content from overflowing */
  min-height: 90%; /* Set a minimum height */
  overflow-y: auto; /* Enables vertical scrolling if the content overflows */
  padding: 10px;
}

.ext-word {
  color: black;
  font-weight: 600;
  font-size: 12px;
}
.p-input input {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 95%;
  height: 100%;
}
.p-input input:focus {
  outline: 0;
  outline-offset: 0;
  background-color: 0;
}
.searchUrl {
  color: #44bfa2;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.p-input input::placeholder {
  color: rgba(82, 104, 146, 0.6);
}
.word_limit {
  color: red;
  display: flex;
  justify-content: end;
}
