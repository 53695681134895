/*
* generate Sets Responsive
*/

/* Tablet */

.topic {
  min-width: 35% !important;
}

.form-container {
  left: 0 !important;
  justify-content: center;
  align-items: center;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .topic {
    width: 50%;
    min-width: 35% !important;
  }
  .item-font {
    font-size: 16px !important;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .topic {
    width: 80%;
    min-width: 35% !important;
  }

  .parah {
    line-height: normal !important;
    display: block;
    margin: 30px 0px 10px 0;
  }

  .item-font {
    font-size: 14px !important;
  }
}
