/*
* QNA General Responsive 
*/
/* Tablet */

@media (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .qna-container {
    padding: 15px 12px;
  }
  .qna-q-title {
    font-size: 14px;
  } 
  .qna-ans-container {
    margin-left: 0.5rem;
  }
  .qna-ans {
    font-size: 13px !important;
  }
}
