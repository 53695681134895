/*
* Preview Responsive 
*/
/* Tablet */

@media (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .preview-watermark {
    background-size: 17rem;
    opacity: 0.03;
  }
  .preview-paper-container {
    padding: 1rem 0.7rem !important;
  }
  .preview-header-container {
    padding-bottom: 0.7rem !important;
  }
  .preview-topic {
    font-size: 14px !important;
  }
  .preview-total {
    font-size: 12px !important;
  }
  .question-section {
    padding: 0 0.5rem !important;
  }
  .question-title {
    font-size: 12px !important;
  }
  .question-ans {
    font-size: 12px !important;
  }
  .option-ml {
    margin-left: 1.6rem !important;
  }
}
