/* jQuery Demo */

.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}

/* Responsive Arrow Progress Bar */

.stepper-container {
	font-family: "Lato", sans-serif;
	width: 100% !important;
	margin: 0;
	background-color: white;
	/* border-left: 2px solid #49c0a0; */
	/* border-right: 2px solid #49c0a0; */
}

.arrow-steps .step {
	font-size: 14px;
	color: #777;
	cursor: default;
	margin: 0;
	padding: 10px 0px 10px 40px;
	width: 32.6%;
	height: 64px;
	float: left;
	position: relative;
	background-color: white;
	border: 2px solid darkgray;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 0px;
	border-right: none;
	border-left: none;
}

.stepper-result {
	width: 32.6%;
	height: 40px;
	background-color: #49c0a0;
	color: white;
}

.arrow-steps .step a {
	/* color: #777; */
	text-decoration: none;
}

.arrow-steps .step:after {
	content: "";
	position: absolute;
	top: 8px;
	right: -23.5px;
	width: 45px;
	height: 45px;
	border: 2px solid;
	z-index: 10;
	border: none;
	border-top: 2px solid darkgray !important;
	border-right: 2px solid darkgray !important;
	background-color: transparent !important;
	transform: rotate(45deg);
}

.arrow-steps .step:last-child:after {
	display: none;
}

.arrow-steps .step:last-child {
	width: 34.8%;
	border-right: 2px solid darkgray;
}
.arrow-steps .step:first-child {
	border-left: 2px solid darkgrey;
}
.arrow-steps .step.current {
	border-color: #49c0a0;
}
.arrow-steps .step.current:after {
	border-color: #49c0a0 !important;
}

.arrow-steps .step span:first-child {
	position: relative;
	/* font-weight: 700; */
	text-transform: uppercase;
}
.arrow-steps .step span {
	position: relative;
	text-transform: uppercase;
}

.arrow-steps .step.current .text {
	color: #49c0a0 !important;
}
.arrow-steps .step .step-tag {
	font-weight: bold !important;
	text-decoration: none;
}

.arrow-steps .step .text {
	color: darkgray !important;
}
.arrow-steps .step .step-tag {
	color: inherit;
}

@media (max-width: 900px) {
	.arrow-steps .step .text {
		color: darkgray !important;
		font-size: 10px !important;
	}
}

@media (max-width: 700px) {
	.arrow-steps .step:after {
		content: "";
		position: absolute;
		top: 9px;
		right: -9.5px;
		width: 53px;
		height: 53px;
		border: 2px solid;
		z-index: 10;
		border: none;
		border-top: 2px solid darkgray !important;
		border-right: 2px solid darkgray !important;
		background-color: transparent !important;
		transform: rotate(45deg);
	}
	.arrow-steps .step:first-child {
		font-size: 8px;
		color: #777;
		cursor: default;
		margin: 0;
		padding: 10px 0px 10px 20px;
		width: 32.6%;
		height: 75px;
	}
	.arrow-steps .step {
		font-size: 8px;
		color: #777;
		cursor: default;
		margin: 0;
		padding: 10px 0px 10px 42px;
		width: 32.6%;
		height: 75px;
	}
}
