/*
* Global Loader Responsive
*/
/* Tablet */

.show-mobile {
  display: none;
}

.divider {
  display: none;
}
.blinkingText {
  animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  0% {
    color: initial; /* Set initial color */
  }
  50% {
    color: #49c0a0; /* Set the color you want during blinking */
  }
  100% {
    color: initial; /* Back to the initial color */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .dd-hidden {
    display: none !important;
  }
  .hide {
    display: none !important;
  }
  .show-mobile {
    display: inline-block;
  }
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-item {
    margin: 0 !important;
  }
  .divider {
    width: 100%;
    margin: 0.9rem 0;
    border-top: 1px solid rgb(203, 203, 203);
    display: block !important;
  }
  .drop-down {
    display: flex;
    flex-direction: column;
  }
  .profile-row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    gap: 0.56rem;
    min-width: 90px !important;
  }
  .notify-count {
    margin-left: -6px !important;
  }
  .header-dropdown-menu > a {
    text-align: center;
  }
  .hoverText-remaining {
    left: -91px !important;
    z-index: 99;
  }
}
