.stepper-title {
    color: black;
    text-align: center;
}

/* Mobile */
@media only screen and (max-width: 767px) {
    .stepper-title {
        font-size: 14px;
        padding: 0 1rem;
    }
}